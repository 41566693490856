<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-11 13:21:56
 * @ Description: Fixer's signup page in landing section of the app.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            style="height: 100vh; max-height: 100vh; color: #2E567A; background-color: rgb(249, 251, 253); position: relative;"
        >
            <!-- Close Button -->
            <v-btn
                icon
                style="position: absolute; right: 16px; top: 12px;"
                @click="close"
                aria-label="close"
            >
                <v-icon
                    color="black"
                    style="z-index: 10;"
                >
                    {{ mdiCloseThick }}
                </v-icon>
            </v-btn>
            <!-- Close Button -->

            <!-- Image -->
            <v-col
                cols="6"
                class="pa-0"
            >
                <v-img
                    src="/landing/signup/general/LandingSignupGeneralBackground_1.webp"
                    width="auto"
                    height="100vh"
                    max-height="100vh"
                />
            </v-col>
            <!-- Image -->

            <!-- Membership Options -->
            <v-col
                cols="6"
                style="max-height: 100vh; overflow-y: auto; display: flex; justify-content: center;"
                align-self="center"
            >
                <v-row
                    style="height: 100%; max-width: 555px;"
                >
                    <v-col
                        cols="12"
                    >
                        <v-img
                            src="/branding/FixerLogoBlue.png"
                            width="170px"
                            class="mx-auto"
                            eager
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-center os-29-sb"
                        style="color: black;"
                    >
                        Choose Membership Option
                    </v-col>
                    <v-col
                        cols="6"
                        class="text-center"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/general/FixerCompanyIcon.png"
                                    width="75px"
                                    height="75px"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-29-sb"
                            >
                                Company
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-14-r"
                            >
                                Post and apply for tenders and <br />
                                projects, find your newest <br />
                                employee manage the <br />
                                recruitment online.
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="signupCompany"
                                >
                                    Sign Up
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="6"
                        class="text-center"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/general/FixerIndividualIcon.png"
                                    width="75px"
                                    height="75px"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-29-sb"
                            >
                                Individual
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-14-r"
                            >
                                Start marketing your skills and <br />
                                get jobs from companies. Post <br />
                                projects and deliver your <br />
                                professional services.
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#2E567A"
                                    @click="signupIndividual"
                                >
                                    Sign Up
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Membership Options -->
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.mdAndDown"
            style="color: #2E567A; min-height: 100vh; position: relative; background-color: rgb(249, 251, 253);"
            align="center"
            justify="center"
        >
            <!-- Close Button -->
            <v-btn
                icon
                style="position: absolute; right: 16px; top: 12px;"
                aria-label="close"
                @click="close"
            >
                <v-icon
                    color="black"
                    style="z-index: 10;"
                >
                    {{ mdiCloseThick }}
                </v-icon>
            </v-btn>
            <!-- Close Button -->

            <v-col
                cols="12"
                class="py-16"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px'"
            >
                <v-row>
                    <!-- Image -->
                    <v-col
                        cols="12"
                    >
                        <v-img
                            src="/branding/FixerLogoBlue.png"
                            width="170px"
                            class="mx-auto"
                            eager
                        />
                    </v-col>
                    <!-- Image -->

                    <!-- Membership Options -->
                    <v-col
                        cols="12"
                        class="text-center os-29-sb"
                        style="color: black;"
                    >
                        Choose Membership Option
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="text-center"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/general/FixerCompanyIcon.png"
                                    :width="$vuetify.breakpoint.md ? '75px' : '50px'"
                                    :height="$vuetify.breakpoint.md ? '75px' : '50px'"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-29-sb"
                            >
                                Company
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-14-r"
                            >
                                Post and apply for tenders and <br />
                                projects, find your newest <br />
                                employee manage the <br />
                                recruitment online.
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="signupCompany"
                                >
                                    Sign Up
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="text-center"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-img
                                    src="/general/FixerIndividualIcon.png"
                                    :width="$vuetify.breakpoint.md ? '75px' : '50px'"
                                    :height="$vuetify.breakpoint.md ? '75px' : '50px'"
                                    contain
                                    class="mx-auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-29-sb"
                            >
                                Individual
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-14-r"
                            >
                                Start marketing your skills and <br />
                                get jobs from companies. Post <br />
                                projects and deliver your <br />
                                professional services.
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#2E567A"
                                    @click="signupIndividual"
                                >
                                    Sign Up
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Membership Options -->
                </v-row>
            </v-col>
        </v-row>
        <!-- Mobile -->
    </div>
</template>

<script>
    import { mdiCloseThick } from '@mdi/js'

    export default {
        name: 'SignupPage',

        data () {
            return {
                mdiCloseThick
            }
        },

        beforeMount () {
            if (!this.$store.state.signup.signupData.type) this.$store.commit('user/resetUserData')
        },

        methods: {
            signupCompany () {
                if (this.$route.path !== '/signup/company') this.$router.push('/signup/company')
            },

            signupIndividual () {
                if (this.$route.path !== '/signup/individual') this.$router.push('/signup/individual')
            },

            close () {
                if (this.$route.path !== '/') this.$router.push('/')
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
